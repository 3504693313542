import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const RessourceCard = props => {
  return (
    <div className="md:flex flex-col bg-white rounded border shadow-lg p-5">
      {props.src !== "empty" &&
        <div className="md:flex-shrink-0 mb-5">
          <img
            className="rounded-lg md:w-56"
            src={props.src}
            alt={props.title}
          />
        </div>
      }
      <div className="mt-4 md:mt-0 md:ml-6">
        <div className="uppercase tracking-wide text-sm text-indigo-600 font-bold">
          {props.title}
        </div>
        <a
          href={props.url}
          className="block mt-1 text-lg leading-tight font-semibold text-gray-900 hover:underline"
        >
          {props.title}
        </a>
        <p className="mt-2 text-gray-600">{props.description}</p>
      </div>
    </div>
  )
}

const RessourcesPage = ({ data }) => {


  return (
    <Layout>
      <SEO title={"Les ressources des SES NOAILLES"} />
      <div className="container">
        <div className="jumbotron px-2">
          <h1 className="text- my-16">Les ressources</h1>
        </div>
        <div className="grid md:grid-cols-2 gap-10 px-2">
          {data.allStrapiRessources.edges.map(ressource => {
            let src
            if(ressource.node.thumbnail != null){
              src = ressource.node.thumbnail.childImageSharp.fixed.src
            } else {
              src = "empty"
            }
            return (
              <RessourceCard
                title={ressource.node.title}
                description={ressource.node.description}
                url={ressource.node.url}
                src={src}
              />
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

RessourcesPage.propTypes = {}

export default RessourcesPage

export const query = graphql`
  query ressourcesPage {
    allStrapiRessources {
      edges {
        node {
          title
          url
          description
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
              fixed {
                src
              }
            }
          }
        }
      }
    }
  }
`
